/* eslint-disable object-shorthand */
import { forEach } from './util';

const getStorage = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const setStorage = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + (ttl * 1000),
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const setUncheckedMessage = (el) => {
  el.querySelector('h3').innerHTML = 'Under 18 years old';
  el.querySelector('.age-check__content').innerHTML = 'You have indicated that you are under 18 years of age, which is too young to visit this website.';
};

export const enhancer = (el) => {
  if (getStorage("ageCheck") !== 'checked') {
    el.classList.add('age-check--show');
  }

  if (getStorage("ageCheck") === 'unchecked') {
    setUncheckedMessage(el);
  }

  const buttons = el.querySelectorAll('.button');
  forEach(buttons, (button) => {
    button.addEventListener('click', e => {
      const check = button.getAttribute('data-check');
      setStorage("ageCheck", check, 84600);
      if (check === 'unchecked') {
        setUncheckedMessage(el);
      } else {
        el.classList.remove('age-check--show');
      }
    });
  });

};
